import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class CelebProvider extends HttpRequest {
  getCelebs (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/celeb', query)
  }

  createCeleb (payload) {
    this.setHeader(getAuthToken())
    return this.post('/celeb', payload)
  }

  editCeleb (id, payload) {
    this.setHeader(getAuthToken())
    return this.put(`/celeb/${id}`, payload)
  }

  deleteCeleb (params) {
    this.setHeader(getAuthToken())
    return this.delete(`/celeb/${params}`)
  }
}

export default CelebProvider
